import React, { useState } from 'react'

import { Box, Divider, Text } from '@chakra-ui/react'
import { useMediaQuery } from '@chakra-ui/react'
import Link from '@components/ui/Link'
import { device } from '@helpers/mediaQueryBreakpoints'
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs'

interface Props {
    title: string
    dataNav: {
        subTitle: string
        link: string
    }[]
}

const CollapsibleNav = ({ title, dataNav }: Props) => {
    const [isOpen, setOpen] = useState(false)
    const [isMobile] = useMediaQuery(device.xs)

    return (
        <Box w="100%" bg="indigo.100">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="4"
                width={isMobile ? '100%' : '90%'}
                mx="auto"
                py="2"
                onClick={() => {
                    setOpen(!isOpen)
                }}
            >
                <Text fontSize="md" fontWeight="bold" color="indigo.500">
                    {title}
                </Text>

                {isOpen ? <BsCaretUpFill /> : <BsCaretDownFill />}
            </Box>

            <Divider borderColor="indigo.500" height="0.5" />

            {isOpen && (
                <>
                    {dataNav.map(({ subTitle, link }) => (
                        <Box key={subTitle} pt="2" width={isMobile ? '100%' : '90%'} mx="auto">
                            <Link to={link} fontSize="sm" color="indigo.500" p="4">
                                {subTitle}
                            </Link>
                        </Box>
                    ))}

                    <Box>
                        <Divider borderColor="indigo.300" height="0.5" mt="4" />
                    </Box>
                </>
            )}
        </Box>
    )
}

export default CollapsibleNav
