import React from 'react'

import { Box, Flex } from '@chakra-ui/react'
import Footer from '@components/core/Footer'
import MenuHeader from '@components/core/MenuHeader'

interface Props {
    children: React.ReactNode
}

export default function Layout({ children }: Props) {
    return (
        <Flex direction="column" justifyContent="space-between" minHeight="100vh">
            <MenuHeader />

            <Box>{children}</Box>

            <Footer />
        </Flex>
    )
}
