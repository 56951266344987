import React from 'react'

import { Link as ChakraUILink, LinkProps as LinkPropsChakra } from '@chakra-ui/react'
import { GatsbyLinkProps } from 'gatsby'
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next'

type GatsbyLinkPropsOverride = GatsbyLinkProps<Record<string, unknown>> & {
    language?: string
}

type LinkProps = LinkPropsChakra & GatsbyLinkPropsOverride
/**
 * Implement Link of gatsby-plugin-react-i18next Link with style of Chakra UI
 * @see https://www.gatsbyjs.com/plugins/gatsby-plugin-react-i18next/
 * @returns
 */
const Link = ({ children, isExternal, to, ...rest }: LinkProps) => {
    return isExternal ? (
        <a href={to} target="_blank" rel="noreferrer">
            {children}
        </a>
    ) : (
        <ChakraUILink as={GatsbyLink} to={to} {...rest}>
            {children}
        </ChakraUILink>
    )
}

export default Link
