import { extendTheme } from '@chakra-ui/react'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/900.css'

/**
 * @link https://chakra-ui.com/docs/styled-system/customize-theme#customizing-component-styles
 * @link https://chakra-ui.com/docs/styled-system/theme#sizes
 */
const theme = {
    colors: {
        indigo: {
            100: '#F3F3FC',
            200: '#DAD6F1',
            300: '#9790BF',
            500: '#24195d',
        },
        green: {
            200: '#ECFAF7',
            500: '#00d4b4',
        },
        pink: {
            200: '#FEEAEF',
            500: '#e91e63',
        },
        grey: {
            100: '#e9edf3',
            200: '#d4dae0',
            300: '#aebac6',
            400: '#98a6b3',
            500: '#555c67',
            600: '#222',
        },
        white: '#FFFFFF',
        yellow: '#FFC303',
        orange: '#FF3D00',
        lavender: '#E5E2F9',
        cadet: '#24195d',
        ultramarine: '#3D5AFE',
        turquoise: '#00D4B4',
    },
    fonts: {
        body: `'Poppins', sans-serif`,
        heading: `'Poppins', sans-serif`,
    },
    fontWeights: {
        normal: 500,
        bold: 700,
        black: 900,
    },
    fontSizes: {
        xs: '0.75rem',
        sm: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
        xl: '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '3.75rem',
        '7xl': '4.5rem',
        '8xl': '6rem',
        '9xl': '8rem',
    },
    radii: {
        none: '0',
        sm: '0.125rem',
        base: '0.25rem',
        md: '0.375rem',
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '3xl': '1.5rem',
        '5xl': '2.5rem',
        full: '9999px',
    },
    space: {
        px: '1px',
        0.5: '0.125rem',
        1: '0.25rem',
        1.5: '0.375rem',
        2: '0.5rem',
        2.5: '0.625rem',
        3: '0.75rem',
        3.5: '0.875rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        12: '3rem',
        14: '3.5rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
    },
    components: {
        Button: {
            // We can update the base styles

            baseStyle: {
                fontWeight: 'bold',
                zIndex: 1,
            },
            //  We can add a new visual variant
            variants: {
                //  We can override existing variants
                outline: () => ({
                    borderWidth: '0.1rem',
                    borderColor: 'green.500',
                    textColor: 'green.500',
                }),
            },
        },

        Link: {
            variants: {
                underlineBold: () => ({
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                }),

                underline: () => ({
                    textDecoration: 'underline',
                }),
            },
        },

        Text: {
            variants: {
                bold: () => ({
                    fontWeight: 'bold',
                    display: 'inline',
                }),

                inline: () => ({
                    display: 'inline',
                }),
            },
        },
    },
}

export default extendTheme(theme)
