import React from 'react'

import { HStack, Icon, Text } from '@chakra-ui/react'
import Link from '@components/ui/Link'
import { IconType } from 'react-icons/lib'

interface IconLinkProps {
    text?: string | null
    to: string
    icon?: IconType
    iconPosition?: 'left' | 'right'
    color?: string
    hoverColor?: string
    pt?: string | string[]
    boxSize?: number
    isExternalLink?: boolean
}

const IconLink = ({
    text,
    to,
    icon,
    iconPosition = 'right',
    color = 'indigo.500',
    hoverColor = 'pink.500',
    boxSize = 5,
    isExternalLink = false,
    ...rest
}: IconLinkProps) =>
    isExternalLink ? (
        <HStack {...rest} color={color} _hover={{ color: hoverColor }}>
            {icon && iconPosition === 'left' && (
                <a href={to} target="_blank" rel="noreferrer">
                    <Icon as={icon} color="inherit" boxSize={boxSize} />
                </a>
            )}

            {text && (
                <Link to={to} isExternal color="inherit" style={{ textDecoration: 'underline' }}>
                    <Text fontSize="sm" fontWeight="bold" align="start" style={{ textDecoration: 'underline' }}>
                        {text}
                    </Text>
                </Link>
            )}

            {icon && iconPosition === 'right' && (
                <a href={to} target="_blank" rel="noreferrer">
                    <Icon as={icon} color="inherit" boxSize={boxSize} />
                </a>
            )}
        </HStack>
    ) : (
        <Link to={to} fontSize="sm" fontWeight="bold" color="inherit">
            <HStack {...rest} color={color} _hover={{ color: hoverColor }}>
                {icon && iconPosition === 'left' && <Icon as={icon} color="inherit" boxSize={boxSize} to={to} />}

                <Text fontSize="sm" align="start" style={{ textDecoration: 'underline', textAlign: 'center' }}>
                    {text}
                </Text>

                {icon && iconPosition === 'right' && <Icon as={icon} color="inherit" boxSize={boxSize} to={to} />}
            </HStack>
        </Link>
    )

export default IconLink
