import React, { useEffect, useState } from 'react'

import { Box, Stack, useMediaQuery } from '@chakra-ui/react'
import { CtaButton, OutlinedButton } from '@components/ui/Button'
import Link from '@components/ui/Link'
import { navigate } from 'gatsby'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

import Logo from '../Logo'
import Section from '../Section'
import NavBar from './NavBar'

const MenuToggle = ({ toggle, isOpen }: { toggle: () => void; isOpen: boolean }) => {
    return (
        <Box display="block" position="absolute" right="4" onClick={toggle}>
            {isOpen ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
        </Box>
    )
}

const MenuHeader = () => {
    const [isOpen, setOpen] = useState(false)
    const [isTablet] = useMediaQuery('(max-width: 800px)')
    const toggle = () => setOpen(!isOpen)

    useEffect(() => {
        if (!isTablet) {
            setOpen(false)
        }
    }, [isTablet])

    return (
        <Box position="sticky" top="0" width="100%" zIndex="10">
            <Section bg="indigo.100">
                <Box display="flex" justifyContent={isTablet ? 'center' : 'space-between'} alignItems="center" py="4">
                    <Link to="/">
                        <Logo width="150px" />
                    </Link>

                    {isTablet ? (
                        <MenuToggle toggle={toggle} isOpen={isOpen} />
                    ) : (
                        <Stack direction="row" align="center" spacing="6">
                            <NavBar direction="row" />

                            <a href="https://www.staffea.net" target="blank">
                                <OutlinedButton text="Se connecter" fontSize="14" />
                            </a>

                            <CtaButton
                                onClick={() => navigate('/contact')}
                                text="Nous contacter"
                                height="10"
                                textButtonFontSize="sm"
                            />
                        </Stack>
                    )}
                </Box>

                <Box display={isTablet && isOpen ? 'block' : 'none'} py={4}>
                    <NavBar direction="column" />

                    <a href="https://www.staffea.net" target="blank">
                        <Box pt="16" pb="2" display="flex" alignItems="center" justifyContent="center">
                            <OutlinedButton text="Se connecter" fontSize="14" />
                        </Box>
                    </a>

                    <a href="/contact">
                        <Box pt="16" pb="2" display="flex" alignItems="center" justifyContent="center">
                            <CtaButton text="Nous contacter" height="10" textButtonFontSize="sm" />
                        </Box>
                    </a>
                </Box>
            </Section>
        </Box>
    )
}

export default MenuHeader
