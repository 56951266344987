import React from 'react'

import { Menu, MenuButton, MenuItem, MenuList, Stack, StackDirection, Text } from '@chakra-ui/react'
import Link from '@components/ui/Link'
import { FiChevronDown } from 'react-icons/fi'

import links from './dataNav.json'

const NavBar = ({ direction }: { direction: StackDirection }) => (
    <Stack spacing={4} align="center" direction={direction}>
        {links.map(({ id, title, link, dataNav }) =>
            !dataNav ? (
                <Menu key={id.toString() + title}>
                    <Link to={link} color="indigo.500" py={2} style={{ textDecoration: 'none' }}>
                        <MenuItem
                            w="fit-content"
                            fontWeight="bold"
                            py={2}
                            borderRadius="md"
                            _hover={{ bg: 'lavender' }}
                        >
                            {title}
                        </MenuItem>
                    </Link>
                </Menu>
            ) : (
                <Menu key={id.toString() + title}>
                    <MenuButton
                        fontWeight="bold"
                        color="indigo.500"
                        borderRadius="md"
                        p={2}
                        transition="all 0.2s"
                        _hover={{ bg: 'lavender' }}
                    >
                        <Stack spacing="1" align="center" direction="row">
                            <Text>{title}</Text>

                            <FiChevronDown />
                        </Stack>
                    </MenuButton>

                    <MenuList zIndex={2}>
                        <Stack itemScope itemType="https://schema.org/SiteNavigationElement">
                            {dataNav.map(function ({ subTitle, link }, index) {
                                return (
                                    <Link
                                        itemProp="url"
                                        to={link}
                                        color="indigo.500"
                                        style={{ textDecoration: 'none' }}
                                        key={index.toString() + subTitle}
                                    >
                                        <MenuItem itemProp="name" closeOnSelect _hover={{ bg: 'lavender' }} py={2}>
                                            {subTitle}
                                        </MenuItem>
                                    </Link>
                                )
                            })}
                        </Stack>
                    </MenuList>
                </Menu>
            )
        )}
    </Stack>
)

export default NavBar
