import React from 'react'

import logo from '@assets/svg/logo-quadri.svg'
import { Box, Image } from '@chakra-ui/react'

interface LogoProps {
    src?: string
    margin?: string
    width?: string
}

const Logo = ({ src = logo, margin = undefined, width }: LogoProps) => (
    <Box>
        <Image src={src} alt="Logo Entreprise Staffea" objectFit="contain" m={margin} w={width} />
    </Box>
)

export default Logo
