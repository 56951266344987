import React, { ReactElement, ReactNode } from 'react'

import { Box, BoxProps, Center } from '@chakra-ui/react'

type SectionProps = BoxProps & {
    bg?: string
    id?: string
    name?: string
    backgroundImage?: string
    children: ReactNode
    footer?: ReactElement
}

const Section = ({ bg = 'white', id, children, footer, ...rest }: SectionProps) => (
    <Box id={id} bg={bg} w="100%" {...rest}>
        <Center>
            <Box w={['90%', '85%', '80%', '75%', '70%']}>{children}</Box>
        </Center>

        {footer}
    </Box>
)

export default Section
